import axios from "axios";
import { baseUrl } from "../constants/constants";

// const token = localStorage.getItem('token')
let GeneralService = {
  new: async (data) => {
    // let data = JSON.stringify(body);
    try {
      const resp = await axios.post(baseUrl + "/waitlists", data, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      // console.log(resp);

      return {
        success: true,
        message: resp?.data?.message ?? "Successufl",
        data: resp?.data?.data,
        // token: resp?.data?.token,
      };
    } catch (e) {
      return {
        success: false,
        message: e?.response?.data?.message ?? e?.message,
      };
    }
  },
};

export { GeneralService };
