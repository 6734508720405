import {
  Typography,
  Button,
  Grid,
  TextField,
  Accordion,
  Box,
  Modal,
  AccordionDetails,
  AccordionSummary,
  CardContent,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { IconArrowDown, IconMenu, IconUser } from "@tabler/icons";
import React from "react";
import LandingTop from "./component/LandingTop";
import landingImageOne from "../../assets/svg/wanchop_delivery.svg";
import lineImage from "../../assets/svg/wanchop_line.svg";
import { useState } from "react";
import "../../assets/css/mystyle.css";
import { useEffect } from "react";
import { GeneralService } from "../../services/general_service";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const LandingPage = () => {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "45%",
    bgcolor: "background.paper",
    // border: '2px solid #000',
    borderRadius: 10,
    boxShadow: 24,
    p: 8,
  };

  const textFieldStyle = {
    root: {
      borderRadius: 20,
    },
  };
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
  };

  const [creating, setCreating] = useState(false);
  const [waitlistBody, setWaitlistBody] = useState({
    email: "",
    phone: "",
    firstname: "",
    lastname: "",
    state: "kano",
  });

  useEffect(() => {}, []);

  const createWaitlist = async () => {
    setCreating(true);
    const newWaitlist = await GeneralService.new(waitlistBody);
    setCreating(false);

    // alert(newWaitlist.message);
    if (!newWaitlist.success) {
      toast.error(newWaitlist.message);
      return;
    }

    toast.success(newWaitlist.message);
    setWaitlistBody({
      ...waitlistBody,
      email: "",
      phone: "",
      firstname: "",
      lastname: "",
    });
    setOpen(false);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    // alert(JSON.stringify(waitlistBody));
    if (
      waitlistBody.email == "" ||
      waitlistBody.phone == "" ||
      waitlistBody.firstname == "" ||
      waitlistBody.lastname == "" ||
      waitlistBody.state == ""
    ) {
      toast.error("Fields cannot be empty");
      return;
    }
    createWaitlist();
  };
  return (
    <div className="landingBg">
      <ToastContainer />
      <Modal
        keepMounted
        open={open}
        onClose={handleClose}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <form action="" onSubmit={handleSubmit}>
          <Box sx={style} className="modalScroll">
            <Grid container justifyContent={"center"}>
              <Typography
                id="keep-mounted-modal-title"
                variant="h6"
                component="h2"
                align="center"
                fontWeight={"bold"}
              >
                Join Our Waitlist
              </Typography>
            </Grid>
            <Grid container justifyContent={"center"}>
              <Typography
                id="keep-mounted-modal-title"
                //   variant="h6"
                component="h2"
                align="center"
                color={"#646982"}
              >
                Please provide these information to join our waitlist
              </Typography>
            </Grid>
            <Grid container spacing={1}>
              <Grid mt={5} px={3} item md={6}>
                <label className="inputLabel">Email</label>
                <input
                  type="email"
                  className="waitField"
                  placeholder="Email"
                  value={waitlistBody?.email}
                  onChange={(e) =>
                    setWaitlistBody({
                      ...waitlistBody,
                      email: e?.target?.value,
                    })
                  }
                  required
                />
                {/* <TextField
                fullWidth
                className="waitField"
                label="Email"
                id="fullWidth"
                type="text"
                // value={adminPayload.email}
                // onChange={(e) =>
                //   setPayload({ ...adminPayload, email: e.target.value })
                // }
              /> */}
              </Grid>
              <Grid mt={5} px={3} item md={6}>
                {/* <TextField
                fullWidth
                className="waitField"
                label="Phone"
                id="fullWidth"
                type="text"
                // value={adminPayload.phoneNumber}
                // onChange={(e) =>
                //   setPayload({ ...adminPayload, phoneNumber: e.target.value })
                // }
              /> */}

                <label className="inputLabel">Phone</label>
                <input
                  type="text"
                  className="waitField"
                  placeholder="Phone"
                  value={waitlistBody?.phone}
                  onChange={(e) =>
                    setWaitlistBody({
                      ...waitlistBody,
                      phone: e?.target?.value,
                    })
                  }
                />
              </Grid>
              <Grid mt={5} px={3} item md={3}>
                {/* <TextField
                fullWidth
                className="waitField"
                label="Name"
                id="fullWidth"
                type="text"
                // value={adminPayload.firstName}
                // onChange={(e) =>
                //   setPayload({ ...adminPayload, firstName: e.target.value })
                // }
              /> */}
                <label className="inputLabel">First name</label>
                <input
                  type="text"
                  className="waitField"
                  placeholder="First name"
                  value={waitlistBody?.firstname}
                  onChange={(e) =>
                    setWaitlistBody({
                      ...waitlistBody,
                      firstname: e?.target?.value,
                    })
                  }
                  required
                />
              </Grid>
              <Grid mt={5} px={3} item md={3}>
                {/* <TextField
                fullWidth
                className="waitField"
                label="Last Name"
                id="fullWidth"
                type="text"
                // value={adminPayload.lastName}
                // onChange={(e) =>
                //   setPayload({ ...adminPayload, lastName: e.target.value })
                // }
              /> */}
                <label className="inputLabel">Last Name</label>
                <input
                  type="text"
                  className="waitField"
                  placeholder="Last Name"
                  value={waitlistBody?.lastname}
                  onChange={(e) =>
                    setWaitlistBody({
                      ...waitlistBody,
                      lastname: e?.target?.value,
                    })
                  }
                  required
                />
              </Grid>
              {/* <Grid mt={5} px={3} item md={6}>
              <TextField
                fullWidth
                className="waitField"
                label="Password"
                id="fullWidth"
                type="password"
                // value={adminPayload.password}
                // onChange={(e) =>
                //   setPayload({ ...adminPayload, password: e.target.value })
                // }
              />
            </Grid> */}
              <Grid mt={5} px={3} item md={6}>
                <label className="inputLabel">City of Residence</label>
                {/* <input
                  type="text"
                  className="waitField"
                  placeholder="City Of Recidence"
                /> */}
                <select
                  className="waitField"
                  onChange={(e) =>
                    setWaitlistBody({
                      ...waitlistBody,
                      state: e?.target?.value,
                    })
                  }
                  required
                >
                  <option value={"kano"}>Kano</option>
                  <option value={"abuja"}>Abuja</option>
                  <option value={"kaduna"}>Kaduna</option>
                  <option value={"kaduna"}>Kaduna</option>
                </select>
                {/* <FormControl fullWidth mt={1}>
                <InputLabel id="demo-simple-select-label">
                  City of Recidence
                </InputLabel>
                <Select
                  //   value={adminPayload.role}
                  //   onChange={(e) =>
                  //     setPayload({ ...adminPayload, role: e.target.value })
                  //   }
                  fullWidth
                >
                  <MenuItem value="admin" selected>
                    Kano
                  </MenuItem>
                </Select>
              </FormControl> */}
              </Grid>
              <Grid pt={5} mt={5} align="center" item md={12}>
                <Button
                  style={{
                    borderRadius: 15,
                    paddingRight: 70,
                    paddingLeft: 70,
                    paddingTop: 10,
                    paddingBottom: 10,
                    backgroundColor: "red",
                  }}
                  disableElevation
                  // disabled={isSubmitting}
                  // fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                  disabled={creating}
                  // color="secondary"
                  // onClick={() => handleOpen()}
                >
                  {creating ? "Submitting" : "join waitlist"}
                </Button>
                <Grid container justifyContent={"center"} mt={2}>
                  <Typography
                    id="keep-mounted-modal-title"
                    //   variant="h6"
                    component="h2"
                    align="center"
                    fontSize={14}
                    color={"#646982"}
                  >
                    Don’t worry, we hate spam too! Your details are safe with
                    us.
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </form>
      </Modal>
      <div className="landingContainer">
        <LandingTop />
        <Grid container py={5} sx={{ px: { md: 10 } }}>
          <Grid item xs={12} md={4} p={5}>
            <Typography
              color={"red"}
              //   gutterBottom
              fontWeight={"bold"}
              fontSize={50}
            >
              WanChop is Almost Here!
            </Typography>
            <Typography
              color={"#32343E"}
              //   gutterBottom
              fontWeight={"normal"}
              fontSize={18}
            >
              Food, groceries, and more – delivered at your doorstep. Be the
              first to experience convenience like never before.
            </Typography>
            <Grid container align={"left"} my={3}>
              <Button
                style={{
                  borderRadius: 15,
                  paddingRight: 30,
                  paddingLeft: 30,
                  paddingTop: 10,
                  paddingBottom: 10,
                  backgroundColor: "red",
                }}
                disableElevation
                // disabled={isSubmitting}
                // fullWidth
                size="large"
                type="submit"
                variant="contained"
                // color="secondary"
                onClick={() => handleOpen()}
              >
                Join our waitlist today
              </Button>
            </Grid>
          </Grid>
          <Grid item xs={12} md={8} pt={3} align="right">
            <img src={landingImageOne} alt="" className="landImage" />
          </Grid>
        </Grid>
      </div>
      {/* <div
        style={{
          backgroundImage: "url(" + { lineImage } + ")",
          height: 120,
          backgroundRepeat: "no-repeat",
        //   objectFit: "cover",
          backgroundSize: "100% 100%",
        }}
        py={5}
      ></div> */}
      <img src={lineImage} width={"100%"} />

      {/* <Grid container mt={5}>
        <LandingFooter />
      </Grid> */}
    </div>
  );
};

export default LandingPage;
